import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import "./Dashboard.scss";
import {
  calendarNumberOutline,
  calendarOutline,
  checkmarkOutline,
  listOutline,
  logOutOutline,
  pauseOutline,
} from "ionicons/icons";
import { useHistory } from "react-router";
import axios from "axios";
import { BASE_URL, mode } from "../BaseUrll";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { setLoggedinUser } from "../customStore";
import { date_maker } from "../functions";
import { Plugins, Capacitor } from "@capacitor/core";
import instance from "../mocks";

const cookies = new Cookies();

const Dashboard: React.FC = () => {
  let history = useHistory();
  const [Username, setUsername] = useState(null);
  const [dashboardData, setDashboardData] = useState<any>({
    member_since: null,
    total_bookings: null,
    total_pending: null,
    total_ready: null,
  });

  if (Capacitor.isNative) {
    Plugins.App.addListener("backButton", (e: any) => {
      if (window.location.pathname === "/") {
        // Show A Confirm Box For User to exit app or not
        let ans = window.confirm("Are you sure");
        if (ans) {
          Plugins.App.exitApp();
        }
      } else if (window.location.pathname === "/dashboard") {
        // Show A Confirm Box For User to exit app or not
        let ans = window.confirm("Are you sure");
        if (ans) {
          Plugins.App.exitApp();
        }
      }
    });
  }

  useEffect(() => {
    const url = BASE_URL + "/get-dashboard";
    if (mode == "demo") {
      instance.get("/get-dashboard").then((response) => {
        setDashboardData(response.data.data);
      });
    } else {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${cookies.get("access_token_lims")}`,
          },
        })
        .then((res) => {
          if (res.data.status) {
            setDashboardData({
              member_since: res.data.member_since,
              total_bookings: res.data.total_bookings,
              total_pending: res.data.total_pending,
              total_ready: res.data.total_ready,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //getting user data
    if (mode == "demo") {
      instance
        .get("/user-account-setting")
        .then((response) => 
        {
            setUsername(response.data.Username)
            setLoggedinUser(response.data.LOGGEDINUSER)
        });
    } else {
      axios
        .get(BASE_URL + "/user-account-setting", {
          headers: {
            Authorization: `Bearer ${cookies.get("access_token_lims")}`,
          },
        })
        .then((res) => {
          if (res.data.status) {
            setUsername(res.data.data.name);
            setLoggedinUser(res.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <IonPage>
      <IonHeader className="ion-no-border dashboard-header">
        <IonToolbar color="primary">
          <IonButton
            slot="end"
            onClick={() => {
              cookies.remove("access_token_lims", {
                path: "/",
              });
              history.push("/login");
            }}
          >
            <IonIcon slot="icon-only" icon={logOutOutline} />
          </IonButton>
          <IonButton
            slot="start"
            onClick={() => {
              history.push("/book-appointment");
            }}
          >
            <IonIcon slot="icon-only" icon={calendarOutline} />
          </IonButton>
        </IonToolbar>
      </IonHeader>

      <IonContent className="dashboard">
        <div className="dashboard-container d-flex">
          <div className="dashboard-top">
            <h3 className="ion-text-center">
              <span>Welcome,</span> {Username}
            </h3>
          </div>
          <div className="dashboard-bottom">
            <h1 className="ion-text-center">Analytics</h1>
            <div className="box d-flex ion-justify-content-between ion-align-items-center">
              <div className="box-icon d-flex ion-align-items-center ion-justify-content-center icon-bg-1">
                <IonIcon icon={listOutline} />
              </div>

              <div className="box-detail">
                <h3 className="ion-text-right">
                  {dashboardData.total_bookings}
                </h3>
                <h4 className="ion-text-right">Total bookings</h4>
              </div>
            </div>

            <div className="box d-flex ion-justify-content-between ion-align-items-center">
              <div className="box-icon d-flex ion-align-items-center ion-justify-content-center icon-bg-2">
                <IonIcon icon={checkmarkOutline} />
              </div>

              <div className="box-detail">
                <h3 className="ion-text-right">
                  {dashboardData.total_pending}
                </h3>
                <h4 className="ion-text-right">Total Pending</h4>
              </div>
            </div>

            <div className="box d-flex ion-justify-content-between ion-align-items-center">
              <div className="box-icon d-flex ion-align-items-center ion-justify-content-center icon-bg-3">
                <IonIcon icon={pauseOutline} />
              </div>

              <div className="box-detail">
                <h3 className="ion-text-right">{dashboardData.total_ready}</h3>
                <h4 className="ion-text-right">Total Ready</h4>
              </div>
            </div>

            <div className="box d-flex ion-justify-content-between ion-align-items-center">
              <div className="box-icon d-flex ion-align-items-center ion-justify-content-center icon-bg-4">
                <IonIcon icon={calendarNumberOutline} />
              </div>

              <div className="box-detail">
                <h3 className="ion-text-right">{dashboardData.member_since}</h3>
                <h4 className="ion-text-right">Member Since</h4>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
