import React, {useEffect, useState} from "react";
import "./TestAccordion.scss";
import {IonChip, IonFabButton, IonIcon, IonLabel, useIonViewWillEnter} from "@ionic/react";
import {addOutline, removeOutline} from "ionicons/icons";
import Tests from "./Tests";

const TestAccordion: React.FC<{obj : Tests}> = (props) => {
    const [collapseIcon, setCollapseIcon] = useState<any>(false);
    const [allTests, setAllTests] = useState<any>(props.obj);
    // const [clickedTabArr, setClickedTabArr] = useState<any>([]);


    useEffect(() =>{
        setAllTests(props.obj);
    }, [props.obj]);

    // useIonViewWillEnter(()=>{
    //     console.log(props.obj);
    //     setAllTests(props.obj);
    // })

    const headerClicked = async (e: any) => {
        
        setCollapseIcon(!collapseIcon);
        var panel = e.currentTarget.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;

        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }

        // e.currentTarget.classList.toggle("active");
        //
        // if (e.currentTarget.classList.contains('active')) {
        //
        // }
        //
        // /* Toggle close all panels, except on that was clicked */
        // const allPanels = document.getElementsByClassName("panel");
        //
        // Array.from(allPanels).forEach((panel: any) => {
        //   if (e.currentTarget.nextElementSibling !== panel) {
        //     panel.style.maxHeight = null;
        //   }
        //   panel.previousElementSibling.classList.remove("active");
        // });

        /* Toggle between hiding and showing the active panel */

        // if(tabIndex.includes(e.target.id)){
        //     var arr = tabIndex;
        //     const index = arr.indexOf(e.target.id);
        //     if (index > -1) {
        //         arr.splice(index, 1);
        //     }
        //     await setTabIndex(arr);
        // }else{
        //     var arr = tabIndex;
        //     arr.push(e.target.id);
        //     await setTabIndex(arr);
        // }

        var arr = allTests;
        if(allTests[e.target.id].clicked){
            arr[e.target.id].clicked = false;
            await setAllTests(arr);
        }else{
            arr[e.target.id].clicked = true;
            await setAllTests(arr);
        }
    };

    return (
        <div>
            {
                allTests.map((item: any, index: any) => {
                    // console.log(item);
                    let d = new Date(item.created_at);
                    let day = d.getDate();
                    let month = d.getMonth() + 1;
                    let year = d.getFullYear();

                    return (
                        <div className="test-card" key={index}>
                            <div id={index} className="accordion-header" onClick={headerClicked}>
                                <div className="d-flex ion-align-items-center" style={{pointerEvents: 'none'}}>
                                    <IonFabButton color="primary"
                                                  className="collapse-icon d-flex ion-justify-content-center ion-align-items-center">
                                      {/* {!collapseIcon ?
                                            <IonIcon className="add-icon" icon={addOutline} />
                                        :
                                            <IonIcon className="add-icon" icon={removeOutline}/>
                                      } */}

                                      {item.clicked?
                                            <IonIcon className="add-icon" icon={removeOutline}/>
                                        :
                                            <IonIcon className="add-icon" icon={addOutline} />
                                      }

                                    </IonFabButton>

                                    <div className="test-body d-flex ion-justify-content-between">
                                        <div className="test-info d-flex ion-justify-content-center">
                                            <h6>
                                                {item.first_name+' '+item.last_name}
                                            </h6>
                                            <span>{day+'-'+month+'-'+year}</span>
                                        </div>

                                        <div className="test-info d-flex ion-justify-content-center">
                                            <h6>
                                                {item.due_amount}
                                            </h6>

                                            <IonChip color="primary">
                                                <IonLabel>{item.report_status.name}</IonLabel>
                                            </IonChip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-panel">
                                <div className="test-table-wrapper">
                                    <table className="test-table">
                                        {/*<thead>*/}
                                        {/*<tr>*/}
                                        {/*    <th>Sr No.</th>*/}
                                        {/*    <th>Test name</th>*/}
                                        {/*    <th>Price</th>*/}
                                        {/*</tr>*/}
                                        {/*</thead>*/}

                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td>{item.first_name+' '+item.last_name}</td>
                                            </tr>

                                            <tr>
                                                <td>Gender</td>
                                                <td>{item.gender}</td>
                                            </tr>

                                            <tr>
                                                <td>Guardian Name</td>
                                                <td>{item.guardian_name}</td>
                                            </tr>

                                            <tr>
                                                <td>D.O.B</td>
                                                <td>{item.dob}</td>
                                            </tr>

                                            <tr>
                                                <td>Email</td>
                                                <td>{item.email}</td>
                                            </tr>

                                            <tr>
                                                <td>Marital Status</td>
                                                <td>{item.marital_status}</td>
                                            </tr>

                                            <tr>
                                                <td>Phone No</td>
                                                <td>{item.phone_no}</td>
                                            </tr>

                                            <tr>
                                                <td>Address</td>
                                                <td>{item.address}</td>
                                            </tr>

                                            <tr>
                                                <td>Net Amount</td>
                                                <td>{item.net_amount}</td>
                                            </tr>

                                            <tr>
                                                <td>Due Amount</td>
                                                <td>{item.due_amount}</td>
                                            </tr>

                                            <tr>
                                                <td>Paid Amount</td>
                                                <td>{item.paid_amount}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table className="test-table">
                                        <thead>
                                            <tr>
                                                <th>Test Name</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                            <tbody className="selected-tests-table">
                                                {item.patient_tests.map((test:any, index:any) =>{
                                                    return(
                                                        <tr key={index}>
                                                            <td>{test.name}</td>
                                                            <td>{test.amount}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                    </table>

                                    <a className="default-btn ion-margin-end" href="#">View Report</a>
                                    <a className="default-btn" href="#">View Invoice</a>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
};

export default TestAccordion;
