import React, {useEffect, useState} from "react";
import {
    IonAvatar,
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonImg, IonItem, IonLabel, IonList,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';

import './Profile.scss';
import {location} from "ionicons/icons";
import {useHistory} from "react-router";
import { LOGGEDINUSER } from  "../customStore";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Profile: React.FC = () => {
    const [user, setUser] = useState<any>();

    let history = useHistory();

    useEffect(()=>{
        setUser(LOGGEDINUSER);
    }, []);

    return (
        <IonPage>
            {/*<IonHeader className="ion-no-border profile-header">*/}
            {/*    <IonToolbar>*/}
            {/*        <IonTitle>Profile</IonTitle>*/}
            {/*    </IonToolbar>*/}
            {/*</IonHeader>*/}

            <IonContent fullscreen className="ion-padding">
                <div className="profile-container">
                    <div className="user-info ion-text-center">
                        <div className="profile-image">
                            <IonAvatar>
                                <IonImg src={require("../assets/images/profile-image.jpg").default}/>
                            </IonAvatar>
                        </div>

                        <h1>{user? user.first_name+' '+user.last_name : ''} </h1>
                        <h3><IonIcon icon={location}/>{user? user.address : ''}</h3>
                    </div>

                    <IonList className="user-setting-list custom-list" lines="none">
                        <IonItem button detail onClick={() =>{history.push('/account-settings')}}>
                            <IonLabel>
                                <h2 className="ion-text-capitalize">Account Settings</h2>
                            </IonLabel>
                        </IonItem>

                        <IonItem button detail onClick={() =>{history.push('/change-password')}}>
                            <IonLabel>
                                <h2 className="ion-text-capitalize">Change Password</h2>
                            </IonLabel>
                        </IonItem>

                        <IonItem button detail onClick={() =>{history.push('/contact-us')}}>
                            <IonLabel>
                                <h2 className="ion-text-capitalize">Contact Us</h2>
                            </IonLabel>
                        </IonItem>

                        <IonItem button detail onClick={() =>{history.push('/about-us')}}>
                            <IonLabel>
                                <h2 className="ion-text-capitalize">About Us</h2>
                            </IonLabel>
                        </IonItem>

                        <IonItem button detail onClick={() =>{
                            cookies.remove('access_token_lims', {
                                path: "/",
                            });
                            history.push('/login');
                            }}>
                            <IonLabel>
                                <h2 className="ion-text-capitalize">Log out</h2>
                            </IonLabel>
                        </IonItem>
                    </IonList>
                </div>

            </IonContent>
        </IonPage>
    );
};

export default Profile;
