import {
    IonBackButton, IonButton,
    IonButtons, IonChip,
    IonContent, IonFabButton,
    IonHeader, IonIcon, IonImg, IonItem, IonLabel,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    IonSpinner
} from '@ionic/react';
import './Tests.scss';
import {addOutline, arrowBackOutline, chevronDownOutline, options, pulseOutline} from "ionicons/icons";
import TestAccordion from "./TestAccordion";
import axios from "axios";
import { BASE_URL,mode } from "../BaseUrll";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import {useHistory} from "react-router";
import instance from '../mocks';

const cookies = new Cookies();

interface Tests {
    [key: number]: Record<string, any>[]
}

const Tests: React.FC = () => {
    let history = useHistory();
    const options = {
        cssClass: 'select-dropdown'
    };

    const [testCategory, setTestCategory] = useState<string>('total');
    const [allTests, setAllTests] = useState<Tests>();
    const [allTestsCopy, setAllTestsCopy] = useState<any>();
    const [allTestsArr, setAllTestsArr] = useState<Tests>();

    useEffect(() =>{
        const url = BASE_URL + "/get-booking-history";

        if(mode == "demo"){
            instance.get("/get-booking-history")
            .then(response => {
                // console.log(response.data.tests)
                if(response.data.status){
                    setAllTests(response.data.data)
                    setAllTestsCopy(response.data.data);
                    setAllTestsArr(response.data.data);
                }
            })
        }else{
            axios
            .get(url, {
                headers: { Authorization: `Bearer ${cookies.get('access_token_lims')}` },
            })
            .then((res) => {
                if(res.data.status){
                    setAllTests(res.data.data);
                    setAllTestsCopy(res.data.data);
                    setAllTestsArr(res.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
       
    }, []);


    const test_filtering = async (arr:any) =>{

    }

    const report_status_filter = async (e:any) =>{
        // console.log(allTests)
        await setTestCategory(e);
        await setAllTestsCopy(allTests);
        var arr:any = [];
        if(e == 'total'){
            arr = allTestsCopy;
        }else if(e == 'completed' && allTestsCopy){
            for await (var item of allTestsCopy)
            {
                // console.log(item)
                if(item.report_status_id >= 7){
                    arr.push(item);
                }
            }
        }else if(e == 'pending' && allTestsCopy){
            for await (var item of allTestsCopy)
            {
                if(item.report_status_id < 7){
                    arr.push(item);
                }
            }
        }
        await setAllTestsArr(arr);
    }

    return (
        <IonPage>
            <IonHeader className="header-center">
                <IonToolbar color="primary">
                    {/* <IonButtons slot="start">
                        <IonBackButton text="" icon={arrowBackOutline} defaultHref=""/>
                    </IonButtons> */}
                    <IonTitle>Tests</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding">
                <div className="test-container">
                    <div className="test-list">
                        <IonIcon icon={chevronDownOutline} />
                        <IonSelect value={testCategory} interface="action-sheet" interfaceOptions={options}
                                   onIonChange={(e)=>{report_status_filter(e.detail.value!)}}>
                            <IonSelectOption value="total">Total Tests </IonSelectOption>
                            <IonSelectOption value="completed">Completed Tests</IonSelectOption>
                            <IonSelectOption value="pending">Pending Tests</IonSelectOption>
                        </IonSelect>
                    </div>
                    {allTestsArr? 
                        <TestAccordion obj = {allTestsArr}/>
                        : 
                        <IonSpinner name="crescent" />
                    }
                        
                </div>

            </IonContent>
        </IonPage>
    );
};

export default Tests;
