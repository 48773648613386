import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSpinner,
  IonToast,
} from "@ionic/react";

import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { logOutOutline } from "ionicons/icons";
import axios from "axios";
import { BASE_URL, URL, mode } from "../BaseUrll";
import Cookies from "universal-cookie";
import instance from "../mocks";

const cookies = new Cookies();

const ChangePassword: React.FC = () => {
  const [oldPassword, setOldPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [doSpin, setDoSpin] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>();
  const [error, setError] = useState<boolean>(false);

  let history = useHistory();

  const update_password = () => {
    setDoSpin(true);
    const url = URL + "/user/change-password";
    if (mode == "demo") {
      instance.post("/user/change-password").then((response) => {
         setToastMessage(response.data.message);
        setError(false);
        setShowToast(true);
        history.push("/profile");
      });
    } else {
      axios
        .post(
          url,
          {
            old_password: oldPassword,
            new_password: newPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${cookies.get("access_token_lims")}`,
            },
          }
        )
        .then(async (res) => {
          setDoSpin(false);
          if (res.data.code == 200) {
            await setToastMessage(res.data.message);
            setError(false);
            setShowToast(true);
            history.push("/profile");
          } else {
            await setToastMessage(res.data.message);
            setError(true);
            setShowToast(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/login" />
          </IonButtons>
          <IonTitle>Change Password</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="ion-padding">
        <div className="login-container d-flex ion-align-items-center">
          <div className="logo">
            <IonImg src={require("../assets/images/logo.png").default} alt="" />
          </div>

          <div className="login-info">
            <IonList lines="none">
              <IonItem className="custom-input">
                <IonInput
                  value={oldPassword}
                  type="password"
                  placeholder="Old Password"
                  onIonChange={(e) => setOldPassword(e.detail.value!)}
                  clearInput
                ></IonInput>
              </IonItem>

              <IonItem className="custom-input">
                <IonInput
                  value={newPassword}
                  type="password"
                  placeholder="New Password"
                  onIonChange={(e) => setNewPassword(e.detail.value!)}
                  clearInput
                ></IonInput>
              </IonItem>
            </IonList>

            <IonButton
              expand="block"
              className="custom-btn"
              onClick={() => {
                update_password();
              }}
            >
              Save
              {doSpin ? (
                <IonSpinner style={{ marginLeft: "20px" }} name="crescent" />
              ) : (
                ""
              )}
            </IonButton>
          </div>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toastMessage}
            duration={1000}
            color={error ? "primary" : "success"}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChangePassword;
