import {
    IonBackButton,
    IonButton, IonButtons,
    IonContent,
    IonHeader, IonIcon, IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSpinner,
    IonToast
} from '@ionic/react';

import './SignUp.scss';
import {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import { BASE_URL, URL } from "../BaseUrll";
import Cookies from "universal-cookie";
import {logOutOutline} from "ionicons/icons";

const cookies = new Cookies();

const ForgetPassword: React.FC = () => {
    const [email, setEmail] = useState<string>();
    const [doSpin, setDoSpin] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>();
    const [error, setError] = useState<boolean>(false);

    let history = useHistory();

    const forget_password = () =>{
        setDoSpin(true);
        const url = URL + "/auth/forget_password";
        axios
        .post(url, {
            email: email
        }, {})
        .then(async (res) => {
            setDoSpin(false);
            if(res.data.status){
                await setToastMessage(res.data.message);
                setError(false);
                setShowToast(true);
            }else{
                if(res.data.message.email)
                {
                    await setToastMessage(res.data.message.email);
                    setError(true);
                    setShowToast(true);
                }else{
                    await setToastMessage(res.data.message);
                    setError(true);
                    setShowToast(true);
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <IonPage>
            <IonHeader className="ion-no-border">
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/login"/>
                    </IonButtons>
                    <IonTitle>Forget Password</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="ion-padding">
                <div className="login-container d-flex ion-align-items-center">
                    <div className="logo">
                        <IonImg src={require("../assets/images/logo.png").default} alt=""/>
                    </div>

                    <div className="login-info">
                        <IonList lines="none">

                            <IonItem className="custom-input">
                                <IonInput value={email} type="email" placeholder="Email"
                                          onIonChange={e => setEmail(e.detail.value!)} clearInput></IonInput>
                            </IonItem>
                        </IonList>

                        <IonButton expand="block" className="custom-btn" onClick={() => {
                            //history.push('/login')
                            forget_password();
                        }}>Send</IonButton>
                    </div>
                    <IonToast
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message={toastMessage}
                        duration={1000}
                        color={error? 'primary' : 'success'}
                    />
                </div>
            </IonContent>
        </IonPage>
    );
};

export default ForgetPassword;
