import {Redirect, Route, Switch} from 'react-router-dom';
import {
    IonApp,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {
    gridOutline,
    layersOutline,
   locationOutline,
  personOutline,
} from 'ionicons/icons';
import Dashboard from './pages/Dashboard';
import Tests from './pages/Tests';
import Branches from './pages/Branches';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';

/* Basic css for all app*/
import './theme/basic.scss';
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ForgetPassword from "./pages/ForgetPassword";
import ChangePassword from "./pages/ChangePassword";
import AboutUs from "./pages/AboutUS";
import ContactUs from "./pages/ContactUs";
import AccountSettings from "./pages/AccountSettings";
import Tabs from "./components/Tabs";
import BookAppointment from "./pages/BookAppointment";
import Location from "./pages/Location";

const App: React.FC = () => (

    <IonApp>
        <IonReactRouter>
            
            <IonRouterOutlet>
            
                <Route path="/login" component={Login} exact/>
                <Route path="/sign-up" component={SignUp} exact/>
                <Route path="/forget-password" component={ForgetPassword} exact/>
                <Route path="/change-password" component={ChangePassword} exact/>
                <Route path="/about-us" component={AboutUs} exact/>
                <Route path="/contact-us" component={ContactUs} exact/>
                <Route path="/account-settings" component={AccountSettings} exact/>
                <Route path="/book-appointment" component={BookAppointment} exact/>
                <Route path="/branch-location" component={Location} exact/>
                <Route path="/" render={() => <Redirect to="/login"/>} exact={true}/>
                <IonTabs>
                    
                    <IonRouterOutlet>
                        
                        <Route exact path="/dashboard" component={Dashboard}/>
                        <Route exact path="/tests" component={Tests}/>
                        <Route exact path="/branches" component={Branches}/>
                        <Route exact path="/profile" component={Profile}/>
                        <Route path="/" render={() => <Redirect to="/dashboard" />} exact={true}/>
                        
                    </IonRouterOutlet>

                    <IonTabBar slot="bottom">
                        <IonTabButton tab="tab1" href="/dashboard">
                            <IonIcon icon={gridOutline}/>
                            <IonLabel>Dashboard</IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="tab2" href="/tests">
                            <IonIcon icon={layersOutline}/>
                            <IonLabel>Tests</IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="tab3" href="/branches">
                            <IonIcon icon={locationOutline}/>
                            <IonLabel>Branches</IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="tab4" href="/profile">
                            <IonIcon icon={personOutline}/>
                            <IonLabel>Profile</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
               
            </IonRouterOutlet>
        </IonReactRouter>
    </IonApp>
);

export default App;
