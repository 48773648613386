import axios from "axios";
import MockAdapter from "axios-mock-adapter";

let instance = axios.create({
  baseURL: "https://some-domain.com/api/",
  timeout: 1000,
  headers: { "X-Custom-Header": "foobar" },
});

let mock = new MockAdapter(instance, { delayResponse: 1000 });
mock.onGet("/users").reply(200, {
  users: [{ id: 1, name: "John Smith" }],
});

mock.onPost("/auth/login").reply(200, {
  code: 200,
  token: {
    token: "NICETOKEN123456",
  },
});

mock.onPost("/user/change-password").reply(200, {
  code: 200,
  status: true,
  message: "successfully changed password.",
});
mock.onGet("/get-dashboard").reply(200, {
  code: 200,
  status: true,
  data: {
    member_since: "06-01-2019",
    total_bookings: 56,
    total_pending: 2,
    total_ready: 30,
  },
});

mock.onGet("/user-account-setting").reply(200, {
  code: 200,
  status: true,
  Username: "ahsan",
  LOGGEDINUSER: {
    first_name: "jhon",
    last_name: "Doe",
    email: "jhon@web.com",
    phone: "0306-0312601",
    address: "asc sdjf sdkl",
    dob: "06-12-2021",
  },
});

mock.onPost("/save-account-setting").reply(200, {
  code: 200,
  status: true,
  Username: "ahsan",
  LOGGEDINUSER: {
    first_name: "jhon",
    last_name: "Doe",
    email: "jhon@web.com",
    phone: "0306-0312601",
    address: "asc sdjf sdkl",
    dob: "06-12-2021",
  },
});

mock.onGet("/collection-center").reply(200, {
  code: 200,
  status: true,
  data: [
    {
      id: 100,
      name: "COVID-19",
    },
    {
      id: 200,
      name: "MALARIA",
    },
  ],
});

mock.onGet("/test").reply(200, {
  code: 200,
  status: true,
  data: [
    {
      id: 100,
      name: "COVID-19",
      tests: [
        {
          amount: 1000,
          name: "PCR-based",
        },
        {
          amount: 2000,
          name: "Antigen",
        },
      ],
    },
    {
      id: 200,
      name: "MALARIA",
      tests: [
        {
          name: "Blood smear",
          amount: 500,
        },
        {
          name: "Rapid diagnostic",
          amount: 300,
        },
      ],
    },
  ],
});

mock.onGet("/get-booking-history").reply(200, {
  code: 200,
  status: true,
  data: [
    {
      first_name: "jhon",
      last_name: "Doe",
      email: "jhon@web.com",
      phone_no: "0306-0312601",
      address: "asc sdjf sdkl",
      dob: "06-12-2021",
      gender:'Male',
      guardian_name:'Doe',
      marital_status:'single',
      net_amount:10000,
      paid_amount:6000,
      due_amount:4000,
      created_at:'06-08-2020',
      report_status:{
        name:'pending'
      },
      patient_tests:[

          {
            name: "Blood smear",
            amount: 500,
          },
          {
            name: "Rapid diagnostic",
            amount: 300,
          }
        
      ]
    },
    {
      first_name: "jhon",
      last_name: "Doe",
      email: "jhon@web.com",
      phone_no: "0306-0312601",
      address: "asc sdjf sdkl",
      dob: "06-12-2021",
      gender:'Male',
      guardian_name:'Doe',
      marital_status:'single',
      net_amount:10000,
      paid_amount:6000,
      due_amount:4000,
      created_at:'06-08-2020',
      report_status:{
        name:'completed'
      },
      patient_tests:[

          {
            name: "Blood smear",
            amount: 500,
          },
          {
            name: "Rapid diagnostic",
            amount: 300,
          }
        
      ]
    },
  ],
});

mock.onGet("/collection-centers").reply(200, {
  code: 200,
  status: true,
  data: [
    {
      name: "COVID-19",
      phone_no1: "0300-2349345",
      longitude: "34.1000",
      latitude: "34.45000",
      address: "asd dfg svb",
    },
    {
      name: "COVID-19",
      phone_no1: "0300-2349334",
      longitude: "34.1000",
      latitude: "34.45000",
      address: "asd dfg svb",
    },
  ],
});

mock.onPost("/save-booking").reply(200, {
  code: 200,
  status: true,
});
export default instance;
