import React, {useEffect, useState} from "react";
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader, IonIcon,
    IonItem, IonLabel,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSpinner
} from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Branches.scss';
import {arrowBackOutline, callOutline, listOutline, locationOutline} from "ionicons/icons";
import axios from "axios";
import { BASE_URL, URL } from "../BaseUrll";
import Cookies from "universal-cookie";
import GoogleMapReact from 'google-map-react';
import {Link, useHistory, useLocation} from "react-router-dom";

const cookies = new Cookies();

const AnyReactComponent = () => (
    <div style={{
      color: 'white', background: 'red', padding: '10px', display: 'inline-flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', borderRadius: '100%', transform: 'translate(-80%, -100%)'
    }}>
      <div className="pointer">
      </div>
    </div>
);

const Location: React.FC = () => {

    const [center, setCenter] = useState<any>({
        lat: 31.416435,
        lng: 73.102616
        });

    const location = useLocation();

    useEffect(() =>{
        const queryParams = new URLSearchParams(location.search);
        // let lat = queryParams.get('lat');
        
        // let lati:any = parseFloat(lat!);
        // console.log(lati);

        
        // let lng = queryParams.get('lng');
        
        setCenter({
            lat: parseFloat(queryParams.get('lat')!),
            lng: parseFloat(queryParams.get('lng')!)
        });
    }, []);

    const renderMarkers = (map:any, maps:any) =>{
        let marker = new maps.Marker({
            position: center,
            map,
            title: 'Hello World!'
          });
    }

    return (
        <IonPage>
            <IonHeader className="header-center">
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton text="" icon={arrowBackOutline} defaultHref=""/>
                    </IonButtons>
                    <IonTitle>Branch Location</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen >
                <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyBKM1SWr7IcDsgwJeaxIa1QQLI0tks5nBY' }}
                defaultCenter={center}
                defaultZoom={16}
                onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
                yesIWantToUseGoogleMapApiInternals
                >
                    {/* <AnyReactComponent /> */}
                </GoogleMapReact>
            </IonContent>
        </IonPage>
    );
};

export default Location;
