import {
  IonButton,
  IonContent,
  IonHeader,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSpinner,
  IonToast,
} from "@ionic/react";

import "./Login.scss";
import { Link, useHistory } from "react-router-dom";
//importing cookies lib for setting static user token
import Cookies from "universal-cookie";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL, URL, mode } from "../BaseUrll";
import { Plugins, Capacitor } from "@capacitor/core";
import instance from "../mocks";

const cookies = new Cookies();

const Login: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [showToast, setShowToast] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [password, setpassword] = useState<string>();
  const [doSpin, setDoSpin] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>();
  const [error, setError] = useState<boolean>(false);

  let history = useHistory();

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e: any) => {
        if (window.location.pathname === "/") {
          // Show A Confirm Box For User to exit app or not
          let ans = window.confirm("Are you sure");
          if (ans) {
            Plugins.App.exitApp();
          }
        } else if (window.location.pathname === "/login") {
          // Show A Confirm Box For User to exit app or not
          let ans = window.confirm("Are you sure");
          if (ans) {
            Plugins.App.exitApp();
          }
        }
      });
    }
  }, []);

  const processLogin = async (res: any) => {
    if (res.data.code == "200") {
        setDoSpin(false);
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        cookies.set("access_token_lims", res.data.token.token, {
          path: "/",
          expires: date,
          sameSite: "lax",
        });
        history.replace("/dashboard");
      } else {
        var result = [];
        for (var i in res.data.message)
          result.push([i, res.data.message[i]]);

        setDoSpin(false);
        setError(true);

        if (res.data.code == 404) {
          await setToastMessage(res.data.message);
        } else {
          await setToastMessage(result[0][1][0]);
        }

        setShowToast(true);
      }
  }

  const handleLogin = () => {
    // let date = new Date();
    // date.setFullYear(date.getFullYear() + 1);
    // cookies.set("access_token_lims", '8|DEN46cRZYwXHf5f9Xuaf84J7BEG3ZYAcqeGurZC8', {
    //     path: "/",
    //     expires: date,
    //     sameSite: "lax",
    // });
    // history.push('/dashboard');
    setDoSpin(true);

    if (mode == "demo") {
      instance.post("/auth/login").then(function (response) {
        processLogin(response);
      });
    } else {
      axios
        .post(
          URL + "/auth/login",
          {
            email: email,
            password: password,
            client: "APP",
          },
          {
            headers: {},
          }
        )
        .then(async (res) => {
            processLogin(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen className="ion-padding">
        <div className="login-container d-flex ion-align-items-center">
          <div className="logo">
            <IonImg src={require("../assets/images/logo.png").default} alt="" />
          </div>

          <div className="login-info">
            <IonList lines="none">
              <IonItem className="custom-input">
                <IonInput
                  value={email}
                  type="email"
                  placeholder="Email"
                  onIonChange={(e) => setEmail(e.detail.value!)}
                  clearInput
                ></IonInput>
              </IonItem>

              <IonItem className="custom-input">
                <IonInput
                  value={password}
                  type="password"
                  placeholder="Password"
                  onIonChange={(e) => setpassword(e.detail.value!)}
                  clearInput
                ></IonInput>
              </IonItem>
            </IonList>

            <IonButton
              expand="block"
              className="custom-btn"
              onClick={() => {
                handleLogin();
              }}
            >
              Log In
              {doSpin ? (
                <IonSpinner style={{ marginLeft: "20px" }} name="crescent" />
              ) : (
                ""
              )}
            </IonButton>

            <Link to="/forget-password">Forget Password?</Link>
          </div>

          <div className="sign-up-link">
            <p>
              Don't have an account? <Link to="/sign-up">Sign Up</Link>
            </p>
          </div>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toastMessage}
            duration={1000}
            color={error ? "primary" : "success"}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
