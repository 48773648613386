import React from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonButtons,
    IonImg,
    IonToolbar,
    IonIcon,
    IonTitle,
    IonBackButton
} from '@ionic/react';
import {arrowBackOutline, star, create} from 'ionicons/icons';
import './AboutUs.scss';


class AboutUs extends React.Component {

    render() {
        return (
            <IonPage>
                <IonHeader className="header-center">
                    <IonToolbar color="primary">
                        <IonButtons slot="start">
                            <IonBackButton text="" icon={arrowBackOutline} defaultHref=""/>
                        </IonButtons>
                        <IonTitle>About Us</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen className="ion-padding">
                    <div className="about-us">
                        <div className="about-detail">
                            <IonImg src={require("../assets/images/logo.png").default}/>

                            <p className="ion-text-center">
                                LIMS is the biggest Diagnostics Company in France giving better quality diagnostics
                                administrations than its clients through an extremely
                                effective system of labs and accumulation focuses. The vision to make LIMS
                                diagnostics was driven by the logic to give high caliber exact tests/results at
                                reasonable costs to the majority.
                            </p>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        );
    }
}

export default AboutUs;
