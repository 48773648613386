import {
    IonButton,
    IonContent,
    IonHeader, IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
    IonToast,
    IonSpinner
} from '@ionic/react';

import './SignUp.scss';
import {useState, useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import { BASE_URL, URL } from "../BaseUrll";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const SignUp: React.FC = () => {
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [address, setAddress] = useState<string>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [confirmPassword, setConfirmPassword] = useState<string>();
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>();
    const [error, setError] = useState<boolean>(false);
    const [doSpin, setDoSpin] = useState<boolean>(false);

    let history = useHistory();

    const signUp = async () =>{
        if(password != confirmPassword){
            await setToastMessage('Confirm password does not match');
            setError(true);
            setShowToast(true);
            return;
        }

        setDoSpin(true);

        setError(false);
        setShowToast(false);
        const url = BASE_URL + "/create-account";
        axios
        .post(url, {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phoneNumber,
            password: password,
            password_confirmation: confirmPassword,
            client: 'APP'
        }, { })
        .then(async (res) => {
            if(res.data.status){
                setDoSpin(false);
                let date = new Date();
                date.setFullYear(date.getFullYear() + 1);
                await cookies.set("access_token_lims", res.data.token.token, {
                    path: "/",
                    expires: date,
                    sameSite: "lax",
                });
                history.push('/dashboard');
            }else{
                setDoSpin(false);
                await setToastMessage('Something went wrong!');
                setError(true);
                setShowToast(true);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

  return (
    <IonPage>
      <IonContent fullscreen className="ion-padding">
       <div className="login-container sign-up-container d-flex ion-align-items-center">
           <div className="logo">
               <IonImg src={require("../assets/images/logo.png").default} alt=""/>
           </div>

           <div className="login-info">
               <IonList lines="none">

                   <IonItem className="custom-input">
                       <IonInput value={firstName} type="text" placeholder="First Name" onIonChange={e => setFirstName(e.detail.value!)} clearInput></IonInput>
                   </IonItem>

                   <IonItem className="custom-input">
                       <IonInput value={lastName} type="text" placeholder="Last Name" onIonChange={e => setLastName(e.detail.value!)} clearInput></IonInput>
                   </IonItem>

                   <IonItem className="custom-input">
                       <IonInput value={email} type="email" placeholder="Email" onIonChange={e => setEmail(e.detail.value!)} clearInput></IonInput>
                   </IonItem>

                   <IonItem className="custom-input">
                       <IonInput value={phoneNumber} placeholder="Phone Number" onIonChange={e => setPhoneNumber(e.detail.value!)} clearInput></IonInput>
                   </IonItem>

                   <IonItem className="custom-input">
                       <IonInput value={password} type="password" placeholder="Password" onIonChange={e => setPassword(e.detail.value!)} clearInput></IonInput>
                   </IonItem>

                   <IonItem className="custom-input">
                       <IonInput value={confirmPassword} type="password" placeholder="Confirm Password" onIonChange={e => setConfirmPassword(e.detail.value!)} clearInput></IonInput>
                   </IonItem>
               </IonList>

               <IonButton expand="block" className="custom-btn" onClick={()=>{signUp()}}>
                   Sign Up
                   {doSpin? <IonSpinner style={{marginLeft: '20px'}} name="crescent" /> : ''}
               </IonButton>

           </div>

           <div className="sign-up-link">
               <p>Already have an account? <Link to="/login">Log In</Link></p>
           </div>
       </div>
       <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toastMessage}
            duration={1000}
            color={error? 'primary' : 'success'}
        />
      </IonContent>
    </IonPage>
  );
};

export default SignUp;
