import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonAvatar,
  IonPage,
  IonSearchbar,
  IonButtons,
  IonList,
  IonToolbar,
  IonIcon,
  IonTitle,
  IonItem,
  IonLabel,
  IonImg,
  IonRadio,
  IonRadioGroup,
  IonListHeader,
  IonTabButton,
  IonButton,
  IonText,
  IonItemDivider,
  IonItemGroup,
  IonDatetime,
  IonToast,
  IonSpinner,
  IonToggle,
  IonAlert,
  IonBackButton,
  useIonViewWillEnter,
} from "@ionic/react";
import { arrowBackOutline, chevronForwardOutline } from "ionicons/icons";
import "./AccountSettings.scss";
import axios from "axios";
import { BASE_URL, mode, URL } from "../BaseUrll";
import Cookies from "universal-cookie";
import { Link, useHistory } from "react-router-dom";
import { setLoggedinUser } from "../customStore";
import { LOGGEDINUSER } from "../customStore";
import instance from "../mocks";

const cookies = new Cookies();

const AccountSettings: React.FC = () => {
  const [showAlert1, setShowAlert1] = useState(false);
  const [alertValue, setAlertValue] = useState<any>("");
  const [user, setUser] = useState<any>();
  const [type, setType] = useState<any>();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [error, setError] = useState<boolean>(false);

  let history = useHistory();

  useEffect(() => {
    // axios
    // .get(BASE_URL + "/user-account-setting", {
    //     headers: { Authorization: `Bearer ${cookies.get('access_token_lims')}` },
    // })
    // .then((res) => {
    //     if(res.data.status){
    //         setUser(res.data.data);
    //     }
    // })
    // .catch((error) => {
    //     console.log(error);
    // });
    setUser(LOGGEDINUSER);
  }, []);

  const setAlert = async (value: any) => {
    if (value == "First Name") {
      await setAlertValue(user.first_name);
      setType(value);
    } else if (value == "Last Name") {
      await setAlertValue(user.last_name);
      setType(value);
    } else if (value == "Phone") {
      await setAlertValue(user.phone);
      setType(value);
    } else if (value == "Address") {
      await setAlertValue(user.address);
      setType(value);
    }
    setShowAlert1(true);
  };

  const updateCreds = async (value: any) => {
    var userData = user;
    if (type == "First Name") {
      userData.first_name = value.item;
      await setUser(userData);
    } else if (type == "Last Name") {
      userData.last_name = value.item;
      await setUser(userData);
    } else if (type == "Phone") {
      userData.phone = value.item;
      await setUser(userData);
    } else if (type == "Address") {
      userData.address = value.item;
      await setUser(userData);
    }

    const url = BASE_URL + "/save-account-setting";
    if(mode == "demo"){
        instance.post("/save-account-setting",{
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone,
            address: user.address,
            dob: user.dob,})
        .then(response => {
            console.log('successfully updated...')
        })
    }else{
        axios
      .post(
        url,
        {
          first_name: user.first_name,
          last_name: user.last_name,
          phone: user.phone,
          address: user.address,
          dob: user.dob,
        },
        {
          headers: {
            Authorization: `Bearer ${cookies.get("access_token_lims")}`,
          },
        }
      )
      .then(async (res) => {
        if (res.data.status) {
          setError(false);
          await setToastMessage("Profile updated successfully!");
          setShowToast(true);
          setLoggedinUser(user);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
    
  };

  return (
    <IonPage>
      <IonHeader className="header-center">
        <IonToolbar color="primary">
          <IonButtons
            onClick={() => {
              history.push("/profile");
            }}
            slot="start"
          >
            <IonBackButton text="" icon={arrowBackOutline} defaultHref="" />
          </IonButtons>
          <IonTitle>Account Settings</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonAlert
        isOpen={showAlert1}
        onDidDismiss={() => setShowAlert1(false)}
        cssClass="input-alert"
        header={"Edit " + type}
        inputs={[
          {
            name: "item",
            type: "text",
            placeholder: "Enter " + type,
            value: alertValue,
          },
        ]}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "Ok",
            handler: (alertData) => {
              updateCreds(alertData);
            },
          },
        ]}
      />

      <IonContent fullscreen className="account-container ion-padding">
        {user ? "" : <IonSpinner name="crescent" />}

        <IonList className="custom-list" lines="none">
          <IonItem
            button
            className=""
            detail
            onClick={() => setAlert("First Name")}
          >
            <IonLabel slot="" id="name">
              <h2 className="ion-text-capitalize">First Name</h2>
            </IonLabel>
            <IonLabel id="name">
              <p className="ion-text-right">{user ? user.first_name : ""}</p>
            </IonLabel>
            {/* <IonIcon slot="end" icon={chevronForwardOutline}/> */}
          </IonItem>

          <IonItem
            button
            className=""
            detail
            onClick={() => setAlert("Last Name")}
          >
            <IonLabel slot="" id="name">
              <h2 className="ion-text-capitalize">Last Name</h2>
            </IonLabel>
            <IonLabel id="name">
              <p className="ion-text-right">{user ? user.last_name : ""}</p>
            </IonLabel>
            {/* <IonIcon slot="end" icon={chevronForwardOutline}/> */}
          </IonItem>

          <IonItem
            button
            className=""
            detail
            disabled={false}
            onClick={() => {}}
          >
            <IonLabel slot="">
              <h2 className="ion-text-capitalize">Email</h2>
            </IonLabel>
            <IonLabel>
              <p className="ion-text-right">
                {user ? user.email : "example.@web.com"}
              </p>
            </IonLabel>
            {/* <IonIcon slot="end" icon={chevronForwardOutline}/> */}
          </IonItem>

          <IonItem
            button
            className=""
            detail
            onClick={() => {
              setAlert("Phone");
            }}
          >
            <IonLabel>
              <h2 className="ion-text-capitalize">Telephone</h2>
            </IonLabel>
            <IonLabel>
              <p className="ion-text-right">{user ? user.phone : ""}</p>
            </IonLabel>
            {/* <IonIcon slot="end" icon={chevronForwardOutline}/> */}
          </IonItem>

          <IonItem
            button
            className=""
            detail
            onClick={() => {
              setAlert("Address");
            }}
          >
            <IonLabel slot="">
              <h2 className="ion-text-capitalize">Address</h2>
            </IonLabel>

            <IonLabel>
              <p className="ion-text-right">{user ? user.address : ""}</p>
            </IonLabel>
            {/* <IonIcon slot="end" icon={chevronForwardOutline}/> */}
          </IonItem>

          <IonItem button className="" detail onClick={() => {}}>
            <IonLabel slot="">
              <h2 className="ion-text-capitalize">Date of birth</h2>
            </IonLabel>

            <IonDatetime
              className="ion-text-right"
              placeholder="DD MMMM YYYY"
              displayFormat="DD MMMM YYYY"
              value={user ? user.dob : ""}
              onIonChange={async (e: any) => {
                var d = new Date(e.detail.value);
                var year = d.getFullYear();
                var month = d.getMonth() + 1;
                var day = d.getDate();
                var userData = user;
                if (userData) {
                  userData.dob = year + "-" + month + "-" + day;
                  await setUser(userData);
                  updateCreds("update");
                }
              }}
            ></IonDatetime>
            {/* <IonIcon slot="end" icon={chevronForwardOutline}/> */}
          </IonItem>
        </IonList>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={1000}
          color={error ? "primary" : "success"}
        />
      </IonContent>
    </IonPage>
  );
};

export default AccountSettings;
