import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
} from "@ionic/react";

import "./BookApointment.scss";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  arrowBackOutline,
  chevronDownOutline,
  closeCircleOutline,
  codeSlashOutline,
} from "ionicons/icons";
import Cookies from "universal-cookie";
import axios from "axios";
import { BASE_URL, URL, mode } from "../BaseUrll";
import instance from "../mocks";

const cookies = new Cookies();

const BookAppointment: React.FC = () => {
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [guardianName, setGuardianName] = useState<string>();
  const [gender, setGender] = useState<string>("male");
  const [email, setEmail] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [dob, setDob] = useState<any>();
  const [user, setUser] = useState<any>({});
  const [maritalStatus, setMaritalStatus] = useState<any>("married");
  const [collectionCenter, setCollectionCenter] = useState<any>("1");
  const [collectionCenters, setCollectionCenters] = useState<any>([]);
  const [availableTests, setAvailableTests] = useState<any>([]);
  const [selectedTests, setSelectedTests] = useState<any>([]);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>();
  const [error, setError] = useState<boolean>(false);
  const [disease,setDiseases] = useState<any>([])
  const [selectedDiseas,setSelectedDiseas]= useState<string>(disease[0]?.name)

  let history = useHistory();

  const options = {
    cssClass: "select-dropdown",
  };

  useEffect(() => {
    if (mode == "demo") {
      instance.get("/user-account-setting").then((response) => {
        if (response.status) {
          setUser(response.data.LOGGEDINUSER);
          setFirstName(response.data.LOGGEDINUSER.first_name);
          setLastName(response.data.LOGGEDINUSER.last_name);
          setPhoneNumber(response.data.LOGGEDINUSER.phone);
          setEmail(response.data.LOGGEDINUSER.email);
        }
      });
    } else {
      axios
        .get(BASE_URL + "/user-account-setting", {
          headers: {
            Authorization: `Bearer ${cookies.get("access_token_lims")}`,
          },
        })
        .then((res) => {
          if (res.data.status) {
            setUser(res.data.data);
            setFirstName(res.data.data.name);
            setEmail(res.data.data.email);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    let url = URL + "/collection-center";
    if (mode == "demo") {
      instance.get("/collection-center").then((response) => {
        // setCollectionCenters(response.data.data);
        // console.log(response.data.data)
        setDiseases(response.data.data)
        // if (response.data.data.length > 0) {
        //   setCollectionCenter(response.data.data[0].id);
        // }

      });
    } else {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${cookies.get("access_token_lims")}`,
          },
        })
        .then((res) => {
          setCollectionCenters(res.data.data);
          if (res.data.data.length > 0) {
            setCollectionCenter(res.data.data[0].id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    url = URL + "/test";
    if (mode == "demo") {
      instance.get("/test").then((response) => {
       const available_tests= response.data.data.map(({id,name,tests}:any)=> {
                if( name === selectedDiseas){
                    // console.log(tests)
                    return tests
                }
        })
        // console.log(available_tests)
        setAvailableTests(()=> [...available_tests]);
      });
    } else {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${cookies.get("access_token_lims")}`,
          },
        })
        .then((res) => {
          setAvailableTests(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const test_selection = async (e: any) => {
    let elem: HTMLElement = document.getElementsByClassName(
      "selected-tests-table"
    )[0] as HTMLElement;
    if (e.detail.value != "choose") {
      var userSelectedTests = selectedTests;

      if (!userSelectedTests.includes(e.detail.value)) {
        elem.innerHTML = "";
        userSelectedTests.push(parseInt(e.detail.value));
        await setSelectedTests(userSelectedTests);

        var total_amount = 0;
        selectedTests.map((item: any, index: any) => {
          total_amount += parseFloat(availableTests[item].amount);
          var tr = document.createElement("tr");
          tr.setAttribute("id", item);
          var td = document.createElement("td");
          td.appendChild(document.createTextNode(availableTests[item].name));
          tr.appendChild(td);
          td = document.createElement("td");
          td.appendChild(document.createTextNode(availableTests[item].amount));
          tr.appendChild(td);
          td = document.createElement("td");
          var btn = document.createElement("BUTTON");
          btn.setAttribute("id", item);
          btn.appendChild(document.createTextNode("remove"));
          btn.classList.add("rm-btn");
          btn.onclick = removeTestFromSelection;
          td.appendChild(btn);
          tr.appendChild(td);

          elem.appendChild(tr);
        });

        var total_tr = document.createElement("tr");
        var total_td = document.createElement("td");

        total_td.appendChild(document.createTextNode("Total"));
        total_tr.appendChild(total_td);

        total_td = document.createElement("td");

        total_td.appendChild(document.createTextNode(total_amount.toString()));
        total_tr.appendChild(total_td);

        elem.appendChild(total_tr);
      }
    }
  };

  const removeTestFromSelection = async (e: any) => {
    var userSelectedTests = selectedTests;
    const index = userSelectedTests.indexOf(parseInt(e.target.id));

    userSelectedTests.splice(index, 1);
    await setSelectedTests(userSelectedTests);

    let elem: HTMLElement = document.getElementsByClassName(
      "selected-tests-table"
    )[0] as HTMLElement;
    elem.innerHTML = "";

    var total_amount = 0;
    userSelectedTests.map((item: any, index: any) => {
      total_amount += parseFloat(availableTests[item].amount);
      var tr = document.createElement("tr");
      var td = document.createElement("td");
      td.appendChild(document.createTextNode(availableTests[item].name));
      tr.appendChild(td);
      td = document.createElement("td");
      td.appendChild(document.createTextNode(availableTests[item].amount));
      tr.appendChild(td);
      td = document.createElement("td");
      var btn = document.createElement("BUTTON");
      btn.setAttribute("id", item);
      btn.appendChild(document.createTextNode("remove"));
      btn.classList.add("rm-btn");
      btn.onclick = removeTestFromSelection;
      td.appendChild(btn);
      tr.appendChild(td);

      elem.appendChild(tr);
    });

    var total_tr = document.createElement("tr");
    var total_td = document.createElement("td");

    total_td.appendChild(document.createTextNode("Total"));
    total_tr.appendChild(total_td);

    total_td = document.createElement("td");

    total_td.appendChild(document.createTextNode(total_amount.toString()));
    total_tr.appendChild(total_td);

    elem.appendChild(total_tr);
  };

  const book_appointment = () => {
    var selectedTestsArr: any = [];
    selectedTests.map((item: any) => {
      selectedTestsArr.push(availableTests[item].id);
    });

    if (selectedTestsArr.length <= 0) {
      setError(true);
      setShowToast(true);
      setToastMessage("Please select a test");
      return;
    }
    setError(false);

    const url = BASE_URL + "/save-booking";
    if (mode == "demo") {
      instance.post("/save-booking").then((response) => {
        // console.log(response);
        if (response.status) {
          setError(false);
           setToastMessage("Booking has been saved");
          setShowToast(true);
          history.push("/dashboard");
        }
      });
    } else {
      axios
        .post(
          url,
          {
            first_name: firstName,
            last_name: lastName,
            guardian_name: guardianName,
            gender: gender,
            email: email,
            phone: phoneNumber,
            dob: dob,
            address: address,
            marital_status: maritalStatus,
            collection_center_id: collectionCenter,
            test_id: selectedTestsArr,
          },
          {
            headers: {
              Authorization: `Bearer ${cookies.get("access_token_lims")}`,
            },
          }
        )
        .then(async (res) => {
          if (res.data.status) {
            setError(false);
            await setToastMessage("Booking has been saved");
            setShowToast(true);
            history.push("/dashboard");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <IonPage>
      <IonHeader className="header-center">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton text="" icon={arrowBackOutline} defaultHref="" />
          </IonButtons>
          <IonTitle>Book an Appointment</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <div className="booking-container">
          <div className="booking-info">
            <IonList lines="none">
              <IonItem className="custom-input">
                <IonInput
                  value={firstName}
                  type="text"
                  placeholder="First Name"
                  onIonChange={(e) => {
                    setFirstName(e.detail.value!);
                  }}
                  clearInput
                ></IonInput>
              </IonItem>

              <IonItem className="custom-input">
                <IonInput
                  value={lastName}
                  type="text"
                  placeholder="Last Name"
                  onIonChange={(e) => {
                    setLastName(e.detail.value!);
                  }}
                  clearInput
                ></IonInput>
              </IonItem>

              <IonItem className="custom-input">
                <IonInput
                  value={guardianName}
                  type="text"
                  placeholder="Guardian Name"
                  onIonChange={(e) => {
                    setGuardianName(e.detail.value!);
                  }}
                  clearInput
                ></IonInput>
              </IonItem>

              <div className="select-list">
                <IonIcon icon={chevronDownOutline} />
                <IonSelect
                  value={gender}
                  interface="action-sheet"
                  interfaceOptions={options}
                  onIonChange={(e) => {
                    setGender(e.detail.value);
                  }}
                >
                  <IonSelectOption value="male">Male</IonSelectOption>
                  <IonSelectOption value="female">Female</IonSelectOption>
                </IonSelect>
              </div>

              <IonItem className="custom-input">
                <IonInput
                  value={email}
                  type="email"
                  placeholder="Email"
                  onIonChange={(e) => {
                    setEmail(e.detail.value!);
                  }}
                  clearInput
                ></IonInput>
              </IonItem>

              <IonItem className="custom-input">
                <IonInput
                  value={phoneNumber}
                  placeholder="Phone Number"
                  onIonChange={(e) => {
                    setPhoneNumber(e.detail.value!);
                  }}
                  clearInput
                ></IonInput>
              </IonItem>

              <IonItem className="custom-input">
                <IonDatetime
                  className="ion-text-right"
                  placeholder="DD MMMM YYYY"
                  displayFormat="DD MMMM YYYY"
                  onIonChange={(e) => {
                    let d = new Date(e.detail.value!);
                    let year = d.getFullYear();
                    let month = d.getMonth() + 1;
                    let day = d.getDate();
                    setDob(year + "-" + month + "-" + day);
                  }}
                ></IonDatetime>
              </IonItem>

              <IonItem className="custom-input">
                <IonInput
                  value={address}
                  type="text"
                  placeholder="Address"
                  onIonChange={(e) => {
                    setAddress(e.detail.value!);
                  }}
                  clearInput
                ></IonInput>
              </IonItem>

              <div className="select-list">
                <IonIcon icon={chevronDownOutline} />
                <IonSelect
                  value={maritalStatus}
                  interface="action-sheet"
                  interfaceOptions={options}
                  onIonChange={(e) => {
                    setMaritalStatus(e.detail.value!);
                  }}
                >
                  <IonSelectOption value="married">Married</IonSelectOption>
                  <IonSelectOption value="single">Single</IonSelectOption>
                </IonSelect>
              </div>

              <div className="select-list">
                <IonIcon icon={chevronDownOutline} />
                {/* <IonSelect
                  value={collectionCenter}
                  interface="action-sheet"
                  interfaceOptions={options}
                  onIonChange={(e) => {
                    setCollectionCenter(e.detail.value!);
                  }}
                >
                  {collectionCenters.map((item: any, index: any) => {
                    
                    return (
                      <IonSelectOption key={index} value={item.id}>
                        {item.name}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect> */}
                
                     <IonSelect
                //   value={collectionCenter}
                  interface="action-sheet"
                  interfaceOptions={options}
                  onIonChange={(e) => {
                    if (mode == "demo") {
                        instance.get("/test").then((response) => {
                         const available_tests= response.data.data.filter(({id,name,tests}:any)=> {
                                  if( id == e.detail.value!){
                                    //   console.log( tests )
                                      return tests
                                  }
                          })
                          // console.log(available_tests[0].tests)
                          setAvailableTests(()=> available_tests[0].tests);
                        });
                      }
                    
                  }}
                >
                  
                  {disease?.map((item: any, index: any) => {
                        
                    return (
                      <IonSelectOption key={index} value={item.id}>
                        {item.name}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </div>

              <div className="select-list">
                <IonIcon icon={chevronDownOutline} />
                <IonSelect
                  value={"choose"}
                  interface="action-sheet"
                  interfaceOptions={options}
                  onIonChange={(e) => {
                    test_selection(e);
                  }}
                >
                  <IonSelectOption value="choose">
                    Choose a Test
                  </IonSelectOption>
                  {availableTests?.map((item: any, index: any) => {
                    // console.log(availableTests[0])
                    return (
                      <IonSelectOption key={index} value={index}>
                        {item?.name}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </div>
            </IonList>

            <div className="test-table-wrapper">
              <table className="test-table">
                <thead>
                  <tr>
                    <th>Test Name</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="selected-tests-table">
                  <tr>
                    <td colSpan={3}>No test selected!</td>
                  </tr>
                  {/* {selectedTests.map((item:number, index:any) =>{

                                            return(
                                            <tr key={index}>
                                                <td>{availableTests[item].name}</td>
                                                <td>{availableTests[item].amount}</td>
                                                <td><IonIcon icon={closeCircleOutline}/></td>
                                            </tr>
                                            )
                                        })} */}
                </tbody>
              </table>
            </div>
            <IonButton
              expand="block"
              className="custom-btn"
              onClick={() => {
                book_appointment();
              }}
            >
              Book Appointment
            </IonButton>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={toastMessage}
              duration={1000}
              color={error ? "primary" : "success"}
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default BookAppointment;
