import React, {useEffect, useState} from "react";
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader, IonIcon,
    IonItem, IonLabel,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSpinner
} from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Branches.scss';
import {arrowBackOutline, callOutline, listOutline, locationOutline} from "ionicons/icons";
import axios from "axios";
import { BASE_URL, mode, URL } from "../BaseUrll";
import Cookies from "universal-cookie";
import {Link, useHistory} from "react-router-dom";
import instance from "../mocks";

const cookies = new Cookies();

const Branches: React.FC = () => {
    let history = useHistory();

    const [collectionCenters, setCollectionCenters] = useState<any>([]);

    useEffect(() =>{
        let url = URL + "/collection-center";
        if(mode === "demo"){
            instance.get("/collection-centers")
            .then(response => {
                setCollectionCenters(response.data.data)
                // console.log(collectionCenters)
            })
        }
        else{
            axios
        .get(url, {
            headers: { Authorization: `Bearer ${cookies.get('access_token_lims')}` },
        })
        .then((res) => {
            setCollectionCenters(res.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
        }
        
    }, []);

    return (
        <IonPage>
            <IonHeader className="header-center">
                <IonToolbar color="primary">
                    {/* <IonButtons slot="start">
                        <IonBackButton text="" icon={arrowBackOutline} defaultHref=""/>
                    </IonButtons> */}
                    <IonTitle>Branches</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="ion-padding">
                {collectionCenters.length > 0? '' :
                    <IonSpinner name="crescent" />
                }
                <div className="branches-container">
                    {collectionCenters.map((item:any, index:any)=>{
                        return(
                            <div key={index} className="ion-padding lab-branch">
                                <a href={`tel:${item.phone_no1}`} className="call-icon d-flex ion-align-items-center ion-justify-content-center">
                                    <IonIcon icon={callOutline}/>
                                </a>

                                <h2>{item.name}</h2>

                                <h4>
                                    <IonIcon icon={callOutline}/>
                                    <span>{item.phone_no1}</span>
                                </h4>

                                <p onClick={()=>{history.push(`/branch-location?lat=${item.latitude}&lng=${item.longitude}`)}}>
                                    <IonIcon icon={locationOutline}/>
                                    <span>{item.address}</span>
                                </p>
                            </div>
                        );
                    })}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Branches;
